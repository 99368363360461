<template>
  <v-container
    fluid
    fill-height
    class="pa-0 align-center justify-center"
  >
    <v-card
      class="elevation-3 pa-10"
    >
      <div class="d-flex align-center justify-center pt-4 pb-4">
        <img
          src="@/assets/Lavirra_logo_SVG.svg"
          height="60px"
          width="auto"
        >
      </div>
      <div class="d-flex justify-center">
        <span>
          {{ $t('common|congratulations_no_stress') }} {{ email }}!
        </span>
      </div>
      <div class="d-flex justify-center">
        <span>
          {{ $t('welcome|account_successfully_verified') }}
        </span>
      </div>
      <div class="d-flex justify-center mt-5">
        <span
          style="font-size: 11px; color: #757575"
        >
          {{ $t('welcome|redirect_to_login') }}
        </span>
      </div>
      <div
        class="d-flex justify-center align-center"
      >
        <span style="font-size: 11px; color: #757575">
          {{ $t('welcome|immediate_login_link') }}
          <router-link to="/login">
            {{ $t('profile|log_in') }}
          </router-link>
        </span>
      </div>
    </v-card>
  </v-container>
</template>

<script>

export default {
  data () {
    return {
      email: null
    }
  },
  mounted () {
    if (this.$route.params && this.$route.params.email) {
      this.email = this.$route.params.email
    }
    setTimeout(() => {
      this.redirectUser()
    }, '10000')
  },
  methods: {
    redirectUser () {
      this.$router.push('/login')
    }
  }
}
</script>
